// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ca-gallery-js": () => import("./../../../src/pages/ca/gallery.js" /* webpackChunkName: "component---src-pages-ca-gallery-js" */),
  "component---src-pages-ca-home-js": () => import("./../../../src/pages/ca/home.js" /* webpackChunkName: "component---src-pages-ca-home-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-es-fotos-js": () => import("./../../../src/pages/es/fotos.js" /* webpackChunkName: "component---src-pages-es-fotos-js" */),
  "component---src-pages-es-home-js": () => import("./../../../src/pages/es/home.js" /* webpackChunkName: "component---src-pages-es-home-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

